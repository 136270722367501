import React from "react";
import Layout from "../components/layout/Layout";
import { Button } from "../components/Button";

export default ({ location }) => {
    return (
        <Layout title="Thanks for the registration!" location={location}>
            <main id="content">
                <section className="hero is-fullheight-with-navbar">
                    <div className="hero-body">
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-9 is-offset-2">
                                    <h1>Thanks for the registration!</h1>
                                    <h2>You will shortly receive a confirmation e-mail from us. If you do not see an e-mail, please make sure to check your Spam and Secondary folders. </h2>
                                    <h2>Questions & Support: <a href="mailto:marketing@frends.com">marketing@frends.com</a></h2>
                                    <Button color="blue" type="outlined" to="/" text="go back home" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}